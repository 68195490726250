<script setup>
import { mdiAccountPlus, mdiLockQuestion, mdiLogin } from '@mdi/js'

useHead({
  title: 'Login',
})

const recaptcha = useRecaptcha()

const route = useRoute()
const { loggedIn, fetch } = useUserSession()

// redirect once user is initialized
watchEffect(() => {
  if (loggedIn.value)
    navigateTo(route?.query?.returnUrl || '/members')
})

const loggingIn = ref(false)
const resettingPassword = ref(false)
const mode = ref(null)
const loginError = ref(null)
const registerError = ref(null)
const passwordRules = ref([])

const name = ref(null)
const email = ref(null)
const password = ref(null)
const confirmPassword = ref(null)

const loginForm = ref()
const registerForm = ref()

async function login() {
  try {
    loggingIn.value = true
    loginError.value = null

    passwordRules.value = [v => !!v || 'Required']

    const { valid } = await loginForm.value.validate()
    if (!valid)
      return

    const token = await recaptcha('login')

    await $fetch('/api/auth/login', {
      method: 'POST',
      body: {
        recaptcha_token: token,
        email: email.value,
        password: password.value,
      },
    })

    await fetch()

    navigateTo(route?.query?.returnUrl || '/')
  }
  catch (error) {
    loginError.value = error?.response?._data
  }
  finally {
    loggingIn.value = false
  }
}

async function register() {
  try {
    loggingIn.value = true
    registerError.value = null

    const { valid } = await registerForm.value.validate()
    if (!valid)
      return

    const token = await recaptcha('register')

    await $fetch('/api/auth/register', {
      method: 'POST',
      body: {
        recaptcha_token: token,
        name: name.value,
        email: email.value,
        password: password.value,
      },
    })

    await fetch()

    navigateTo(route?.query?.returnUrl || '/')
  }
  catch (error) {
    registerError.value = error?.response?._data
    loggingIn.value = false
  }
}

async function resetPassword() {
  try {
    resettingPassword.value = true
    loginError.value = null

    passwordRules.value = []

    const { valid } = await loginForm.value.validate()
    if (!valid)
      return

    const token = await recaptcha('forgotPassword')

    await $fetch('/api/auth/forgot-password', {
      method: 'POST',
      body: {
        recaptcha_token: token,
        email: email.value,
      },
    })

    loginError.value = {
      message: 'A password reset link has been sent to the email address provided!',
      type: 'success',
    }
  }
  catch (error) {
    loginError.value = error?.response?._data
  }
  finally {
    resettingPassword.value = false
  }
}
</script>

<template>
  <div class="bg">
    <v-container class="h-100">
      <v-row
        class="h-100"
        align="center"
        justify="center"
      >
        <v-col cols="auto">
          <v-card width="400">
            <v-card-title class="text-h5 text-center">
              Member Login
            </v-card-title>

            <v-tabs
              v-model="mode"
              density="comfortable"
              color="primary"
              grow
            >
              <v-tab value="login">
                Login
              </v-tab>
              <v-tab value="register">
                Register
              </v-tab>
            </v-tabs>
            <v-divider />

            <v-window v-model="mode">
              <v-window-item value="login">
                <v-form
                  ref="loginForm"
                  :disabled="loggingIn || resettingPassword"
                  @submit.prevent="login"
                >
                  <v-card-text>
                    <v-text-field
                      v-model="email"
                      label="Email"
                      type="email"
                      maxlength="255"
                      :rules="[
                        v => !!v || 'Required',
                        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid Email Address',
                      ]"
                    />

                    <PasswordInput
                      v-model="password"
                      hide-details="auto"
                      label="Password"
                      maxlength="50"
                      :rules="passwordRules"
                    />

                    <v-alert
                      v-if="loginError"
                      class="mt-5"
                      density="compact"
                      :type="loginError.type || 'error'"
                      :text="loginError.message"
                    />
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-btn
                      variant="elevated"
                      :prepend-icon="mdiLockQuestion"
                      :disabled="loggingIn || resettingPassword"
                      :loading="resettingPassword"
                      @click="resetPassword"
                    >
                      Forgot Password
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      type="submit"
                      color="primary"
                      variant="elevated"
                      :prepend-icon="mdiLogin"
                      :disabled="loggingIn || resettingPassword"
                      :loading="loggingIn"
                    >
                      Login
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-window-item>

              <v-window-item value="register">
                <v-form
                  ref="registerForm"
                  :disabled="loggingIn || resettingPassword"
                  @submit.prevent="register"
                >
                  <v-card-text>
                    <v-text-field
                      v-model="name"
                      label="Full Name"
                      maxlength="100"
                      :rules="[
                        v => !!v || 'Required',
                      ]"
                    />
                    <v-text-field
                      v-model="email"
                      label="Email"
                      type="email"
                      maxlength="255"
                      :rules="[
                        v => !!v || 'Required',
                        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid Email Address',
                      ]"
                    />

                    <PasswordInput
                      v-model="password"
                      label="Password"
                      maxlength="50"
                      :rules="[
                        v => !!v || 'Required',
                        v => v && v.length >= 6 || 'Must be at least 6 characters',
                      ]"
                    />

                    <PasswordInput
                      v-model="confirmPassword"
                      hide-details="auto"
                      label="Confirm Password"
                      maxlength="50"
                      :rules="[
                        v => !!v || 'Required',
                        v => v && v === password || 'Passwords do not match',
                      ]"
                    />

                    <v-alert
                      v-if="registerError"
                      class="mt-5"
                      density="compact"
                      type="error"
                      :text="registerError.message"
                    />
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      type="submit"
                      color="primary"
                      variant="elevated"
                      :prepend-icon="mdiAccountPlus"
                      :disabled="loggingIn || resettingPassword"
                      :loading="loggingIn"
                    >
                      Register
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
  .bg {
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/img/homepage/hero.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>
